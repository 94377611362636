@import url(https://fonts.googleapis.com/css2?family=Montserrat&family=Poppins&display=swap);
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root:root {
  --adm-color-primary: #541388;
  --adm-color-box: #f0f2f5;
}

body {
  font-family: 'Montserrat', sans-serif;
  overflow: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}
.App {
  text-align: center;
}

.content-wrapper {
  margin-top: 24px;
  margin-bottom: 12px;
}

.content-container {
  max-width: 1024px;
  background: #fff;
  width: 100%;
  margin: 0 auto;
  padding: 24px 48px;
}

@media only screen and (max-width: 768px) {
  .content-container {
    max-width: 1024px;
    background: #fff;
    width: 100%;
    margin: 0 auto;
    padding: 24px 16px;
  }
}

.pricing-calculator {
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ant-btn-link {
  color: #541388;
}
.ant-btn-link:not(:disabled):hover {
  color: #cb47b8;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

